import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';
import datePickerLocale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  data: function data() {
    return {
      tabId: 1,
      animated: false,
      datePickerLocale: datePickerLocale,
      dateFormat: 'YYYY-MM-DD',
      search: {
        stationId: localStorage.getItem('stationId'),
        startTime: moment().subtract(3, 'months'),
        endTime: moment(),
        searchDate: moment().subtract(1, 'day'),
        equipmentId: '-1',
        signalId: -1,
        packId: 1
      },
      packCount: 14,
      equipmentList: [],
      datas: [],
      accumulated_chart: null,
      eblock_chart: null,
      cell_chart: null,
      colors: ['#1890FF', '#66B5FF', '#B3DAFF', '#E6F3FF', '#F26813', '#F29861', '#F2C5A9', '#F2E3DA', '#49AA19', '#66AA44', '#88AA77', '#9FAA99', '#7D2CF5', '#9D62F5', '#C9ABF5', '#E6DDF5']
    };
  },
  mounted: function mounted() {
    var this_ = this;
    this_.getEquipmentList();
  },
  methods: {
    onChange: function onChange() {
      if (typeof this.search.startTime === 'string') {
        this.search.startTime = this.search.startTime.split(" ")[0];
        this.search.startTime = moment(this.search.startTime);
      }

      if (typeof this.search.endTime === 'string') {
        this.search.endTime = this.search.endTime.split(" ")[0];
        this.search.endTime = moment(this.search.endTime);
      }

      if (typeof this.search.searchDate === 'string') {
        this.search.searchDate = this.search.searchDate.split(" ")[0];
        this.search.searchDate = moment(this.search.searchDate);
      }
    },
    onEquipmentIdChange: function onEquipmentIdChange() {
      var this_ = this;
      this_.equipmentList.map(function (item) {
        if (item.id == this_.search.equipmentId) {
          this_.packCount = item.cellCount / 16;
        }
      });
    },
    getEquipmentList: function getEquipmentList() {
      var this_ = this;
      var data = {
        typeId: 3,
        stationId: localStorage.getItem('stationId')
      };
      axios({
        method: 'post',
        url: '/emind/config/equipment/search_condition',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.equipmentList = d.data.data;
          this_.equipmentList.sort(this_.sortNum);

          if (this_.equipmentList.length > 0) {
            this_.packCount = this_.equipmentList[0].cellCount / 16;
            this_.search.equipmentId = this_.equipmentList[0].id;
            this_.reflush();
          }
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    sortNum: function sortNum(a, b) {
      if (a.typeId == 3 && b.typeId == 3) {
        return (a.num - b.num) * 1;
      } else if (a.typeId == 3) {
        return -1;
      } else {
        return a.typeId - b.typeId;
      }
    },
    tabsChange: function tabsChange(activeKey) {
      var this_ = this;

      if (this_.tabId == 1) {
        this_.accumulated_chart.dispose();
      } else if (this_.tabId == 2) {
        this_.eblock_chart.dispose();
      } else if (this_.tabId == 3) {
        this_.cell_chart.dispose();
      } else {
        return;
      }

      this_.tabId = activeKey;
      setTimeout(function () {
        this_.reflush();
      }, 100);
    },
    reflush: function reflush() {
      var this_ = this;
      var data = {};
      data.stationId = this.search.stationId;
      data.equipmentId = this.search.equipmentId;

      if (this.tabId == 1) {
        data.startTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
        data.endTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
        data.signalId = 1;
        this.accumulated_chart = this_.$echarts.init(document.getElementById("accumulated_chart"));
        this.accumulated_chart.showLoading({
          text: '数据正在加载。。。',
          color: '#4cbbff',
          textColor: '#4cbbff',
          maskColor: 'rgba(61, 72, 85, 0.65)'
        });
      } else if (this.tabId == 2) {
        if (this.search.searchDate == null) {
          this.$message.warning('请先选择查询时间！');
          return;
        }

        data.endTime = this.search.searchDate.format('YYYY-MM-DD ') + " 00:00:00";
        data.signalId = 2;
        this.eblock_chart = this_.$echarts.init(document.getElementById("eblock_chart"));
        this.eblock_chart.showLoading({
          text: '数据正在加载。。。',
          color: '#4cbbff',
          textColor: '#4cbbff',
          maskColor: 'rgba(61, 72, 85, 0.65)'
        });
      } else if (this.tabId == 3) {
        if (this.search.startTime == null) {
          this.$message.warning('请先选择起始时间！');
          return;
        }

        data.startTime = this_.search.startTime.format('YYYY-MM-DD ') + "00:00:00";
        data.endTime = this_.search.endTime.format('YYYY-MM-DD ') + "23:59:59";
        data.signalId = 3;
        data.startIndex = (this.search.packId - 1) * 16 + 1;
        data.length = this.search.packId * 16;
        this.cell_chart = this_.$echarts.init(document.getElementById("cell_chart"));
        this.cell_chart.showLoading({
          text: '数据正在加载。。。',
          color: '#4cbbff',
          textColor: '#4cbbff',
          maskColor: 'rgba(61, 72, 85, 0.65)'
        });
      } else {
        return;
      }

      axios({
        method: 'post',
        url: '/emind/history/data/cell/health/analysis/data',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        var newOp = this_.getOption();

        if (this_.tabId == 1) {
          this_.datas = [];

          if (d.data.data.length > 0) {
            var key = '';

            for (var i = 1; i <= this_.packCount * 16; i++) {
              key = 'v' + i;
              this_.datas.push([i, d.data.data[0][key].toFixed(1)]);
            }
          }

          newOp.series = {
            type: 'bar',
            itemStyle: {
              color: '#1890FF'
            },
            animation: false,
            connectNulls: false,
            color: '#c74845',
            data: this_.datas
          };
          newOp.grid = {
            left: 50,
            right: 50,
            height: 580
          };
          newOp.xAxis = {
            nameTextStyle: {
              color: '#7C868D',
              fontFamily: "微软雅黑"
            },
            splitLine: {
              show: false
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#7C868D'
              }
            },
            name: '位置',
            type: 'value',
            min: 1,
            max: this_.packCount * 16,
            axisTick: {
              alignWithLabel: true
            }
          };
          this_.accumulated_chart.setOption(newOp);
          this_.accumulated_chart.resize();
          this_.accumulated_chart.hideLoading();
          setTimeout(function () {
            window.onresize = function () {
              this_.accumulated_chart.resize();
            };
          }, 500);
        } else if (this_.tabId == 2) {
          this_.datas = [];

          if (d.data.data.length > 0) {
            var key = '';

            for (var i = 1; i <= this_.packCount * 16; i++) {
              key = 'v' + i;
              this_.datas.push([i, d.data.data[0][key]]);
            }
          }

          newOp.series = [];
          newOp.series.push({
            type: 'line',
            itemStyle: {
              color: '#1890FF'
            },
            animation: false,
            connectNulls: false,
            color: '#c74845',
            data: this_.datas
          });
          newOp.xAxis = {
            nameTextStyle: {
              color: '#7C868D',
              fontFamily: "微软雅黑"
            },
            splitLine: {
              show: false
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#7C868D'
              }
            },
            name: '位置',
            type: 'value',
            min: 1,
            max: this_.packCount * 16,
            axisTick: {
              alignWithLabel: true
            }
          };
          newOp.tooltip = {
            trigger: 'axis',
            axisPointer: {
              animation: false
            },
            formatter: function formatter(params) {
              if (params[0].value == '' && params[1].value == '') {
                return '没有数据';
              }

              var relVal = '';

              if (params[0].seriesIndex == 0) {
                relVal = '电芯位置：第' + params[0].value[0] + '节<br/>' + '单日电压标准差：' + params[0].value[1];
              } else {
                relVal = '暂无数据';
              }

              return relVal;
            }
          };
          this_.eblock_chart.setOption(newOp);
          this_.eblock_chart.resize();
          this_.eblock_chart.hideLoading();
          setTimeout(function () {
            window.onresize = function () {
              this_.eblock_chart.resize();
            };
          }, 500);
        } else if (this_.tabId == 3) {
          var dates = [];

          for (var i = new Date(data.startTime).getTime(); i <= new Date(data.endTime).getTime(); i += 86400000) {
            dates.push(new Date(i).Format("yyyy-MM-dd"));
          }

          var key;
          var legend = {
            textStyle: {
              fontFamily: "微软雅黑",
              color: '#7C868D'
            },
            data: [],
            x: 'left',
            top: 47,
            left: 60
          };
          newOp.series = [];

          for (var i = data.startIndex; i <= data.length; i++) {
            key = 'v' + i;
            legend.data.push(key);
            var datas = [];
            datas = d.data.data.map(function (x) {
              return {
                value: [new Date(x.recordTime).Format("yyyy-MM-dd"), x[key]]
              };
            });
            newOp.series.push({
              name: key,
              type: 'line',
              itemStyle: {
                color: this_.colors[i - data.startIndex]
              },
              barWidth: 10,
              animation: false,
              connectNulls: false,
              color: '#c74845',
              data: datas
            });
          }

          newOp.legend = legend;
          newOp.grid = {
            left: 30,
            top: 80,
            right: 50,
            height: 580
          }, newOp.xAxis = {
            nameTextStyle: {
              color: '#7C868D',
              fontFamily: "微软雅黑"
            },
            name: '时间',
            type: 'category',
            data: dates,
            axisLabel: {
              formatter: function formatter(value, index) {
                // 格式化成月/日，只在第一个刻度显示年份
                var date = new Date(value);
                return date.Format("MM-dd\nyyyy");
              }
            },
            splitLine: {
              show: false
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: '#7C868D'
              }
            },
            axisTick: {
              alignWithLabel: true
            }
          };
          newOp.tooltip = {
            trigger: 'axis',
            axisPointer: {
              animation: false
            },
            formatter: function formatter(params) {
              var str = "均方差单日值" + '   ';

              if (params != null && params.length > 0) {
                str += params[0].name + '<br/>';

                for (var _i = 0; _i < params.length; _i++) {
                  str += params[_i].marker + params[_i].seriesName + ":    " + params[_i].value[1] + '<br/>';
                }
              }

              return str;
            }
          }, this_.cell_chart.setOption(newOp);
          this_.cell_chart.resize();
          this_.cell_chart.hideLoading();
          setTimeout(function () {
            window.onresize = function () {
              this_.cell_chart.resize();
            };
          }, 500);
        }
      }).catch(function (error) {
        if (this_.tabId == 1) {
          this_.accumulated_chart.hideLoading();
        } else if (this_.tabId == 2) {
          this_.eblock_chart.hideLoading();
        } else if (this_.tabId == 3) {
          this_.cell_chart.hideLoading();
        }

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getOption: function getOption() {
      var this_ = this;
      return {
        title: {
          textStyle: {
            fontFamily: "微软雅黑",
            color: '#E2E2E2'
          },
          text: '电芯电压分析曲线',
          x: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            animation: false
          },
          formatter: function formatter(params) {
            if (params[0].value == '' && params[1].value == '') {
              return '没有数据';
            }

            var relVal = '';

            if (params[0].seriesIndex == 0) {
              relVal = '电芯位置：第' + params[0].value[0] + '节<br/>' + '电压累计差：' + params[0].value[1];
            } else {
              relVal = '暂无数据';
            }

            return relVal;
          }
        },
        toolbox: {
          show: true,
          right: 20,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        },
        axisPointer: {
          link: {
            xAxisIndex: 'all'
          }
        },
        grid: {
          left: 30,
          right: 50,
          height: 580
        },
        xAxis: {
          nameTextStyle: {
            color: '#7C868D',
            fontFamily: "微软雅黑"
          },
          splitLine: {
            show: false
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#7C868D'
            }
          },
          name: '位置',
          type: 'value',
          min: 1,
          max: this_.packCount * 16,
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          nameTextStyle: {
            fontFamily: "微软雅黑"
          },
          axisLine: {
            lineStyle: {
              color: '#7C868D'
            }
          },
          splitLine: {
            lineStyle: {
              color: '#7C868D'
            }
          },
          name: '电压(mV)',
          type: 'value'
        },
        series: {
          type: 'bar',
          connectNulls: false,
          animation: true,
          lineStyle: {
            color: '#1890FF',
            width: 1
          },
          data: this_.datas
        }
      };
    },
    searchFn: function searchFn() {
      var this_ = this;
      this_.reflush();
    },
    exportExcel: function exportExcel(url) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var name = arguments.length > 2 ? arguments[2] : undefined;
      return new Promise(function (resolve, reject) {
        axios({
          method: 'post',
          url: url,
          // 请求地址
          data: options,
          // 参数
          responseType: 'blob' // 表明返回服务器返回的数据类型

        }).then(function (response) {
          resolve(response.data);
          var blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });
          var fileName = name;

          if (response.headers['content-disposition'].endsWith('.xls"')) {
            fileName = fileName + '.xls';
          } else if (response.headers['content-disposition'].endsWith('.zip"')) {
            fileName = fileName + '.zip';
          }

          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click(); //释放内存

            window.URL.revokeObjectURL(link.href);
          }
        }, function (err) {
          if (error.message != '') {
            this_.$Message.error(error.message, 2, function () {});
            return;
          }
        });
      });
    },
    exportFn: function exportFn() {
      var this_ = this;
      this.search.endTime = new Date().Format("yyyy-MM-dd hh:mm:ss");
      var now = new Date();
      var url = '/emind/history/data/power_soc_chart/export/' + now.getTime();
      this_.exportExcel(url, this_.search, '功率电量-' + now.Format("yyyy-MM-dd_hh-mm-ss"));
    }
  }
};